import React, { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { buildQueryString } from '@edgeco/react-components';

import { ProductionDetailLinkType, ProductionDetailFilter } from '../types';
import LinkButton from './LinkButton';

const useStyles = makeStyles<EdgeCoTheme>(({ extensions: { color } }) => ({
  detailLink: {
    color: color.text.primary,
    textDecorationColor: color.text.primary,
    fontSize: '1.2rem',
  },
  guillemet: {
    fontSize: '1.6rem',
  },
}));

export type ProductionDetailLinkProps = PropsWithChildren<{
  linkType?: ProductionDetailLinkType;
  filter: ProductionDetailFilter;
  className?: string;
}>;

function ProductionDetailLink({
  children,
  className,
  filter,
  linkType,
}: ProductionDetailLinkProps) {
  const linkProps: LinkProps = {
    to: (location: any) => ({
      ...location,
      pathname: '/production-detail',
      search: buildQueryString(filter),
    }),
    className,
  };

  const classes = useStyles();
  switch (linkType) {
    case ProductionDetailLinkType.DetailLink:
      return (
        <>
          <Link {...linkProps} className={classes.detailLink}>
            <span>See More Detail</span>
          </Link>
          <span className={classes.guillemet}>&#187;</span>
        </>
      );
    case ProductionDetailLinkType.ReviewButton:
      return <LinkButton {...linkProps}>Review Full Report</LinkButton>;
    default:
      return <Link {...linkProps}>{children}</Link>;
  }
}

export default ProductionDetailLink;
