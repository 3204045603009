import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { WithStyles, createStyles, withStyles } from '@material-ui/core';

import {
  useMeasure,
  useAppStateValue,
  ActionType,
} from '@edgeco/react-components';

import { useRepCacheInitialization } from 'edgeco/graphql/advisor-summary/queries/selectedReps';
import Topbar from './Topbar';

const styles = () =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      boxSizing: 'border-box',
    },
  });

interface DecoratedProps
  extends WithStyles<typeof styles>,
    Partial<RouteComponentProps> {
  children?: any;
}
/**
 * This serves as the root component for all application specific components.
 *
 */
function Root({ classes, children }: DecoratedProps) {
  const { ref, measurement } = useMeasure();
  const test = useAppStateValue();
  // eslint-disable-next-line no-debugger
  if (!Array.isArray(test)) debugger;
  const [, dispatch] = test;
  useRepCacheInitialization();
  useEffect(() => {
    dispatch({
      type: ActionType.UpdateScroll,
      payload: measurement.scroll,
    });
  }, [dispatch, measurement]);

  return (
    <div className={classes.root} ref={ref}>
      <Topbar />
      {children}
    </div>
  );
}

export default withStyles(styles)(Root);
