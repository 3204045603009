import React, { useMemo } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Theme,
  emphasize,
  useTheme,
} from '@material-ui/core';
import numeral from 'numeral';

import {
  colorScheme,
  ComparisonButtonTabBar,
  BarChart,
  YAxis,
  useYAxis,
  XAxis,
  Title,
  Loading,
  TooltipAxisTick,
  ChartDataShape,
  Series,
} from '@edgeco/react-components';
import { ApolloError } from '@apollo/client';
import { useTrackComponent } from 'edgeco/hooks/AppInsights';

export type ChartData = {
  key: string | number;
  data: TopYearlyComparison;
};

type Props = {
  selectedYear?: number;
  onYearChange: (e: any, year: string) => void;
  loading?: boolean;
  chartData?: ChartData[];
  domain: [number, number];
  populatedYears: Set<number>;
  error?: ApolloError;
  title: string;
  trackingName: string;
  dataPoint: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  tooltipTotal: {
    fontWeight: 'bold',
  },
  xAxisTick: {
    ...theme.typography.caption,
  },
}));

function YearlyTopBarChart({
  selectedYear,
  onYearChange,
  loading,
  chartData,
  domain,
  populatedYears,
  error,
  title,
  trackingName,
  dataPoint,
}: Props) {
  const { tooltipTotal } = useStyles();
  const {
    extensions: { color },
  } = useTheme<EdgeCoTheme>();

  const currentYear = new Date().getFullYear();
  const series = useMemo(() => {
    const rv: Series[] = [
      {
        dataKey: (d: ChartDataShape<TopYearlyComparison>) =>
          d.data?.current?.value,
        metaKey: (d: TopYearlyComparison) => d?.current,
        style: { transform: `translate(${selectedYear ? 2 : 2.6}%, 0px)` },
      },
    ];
    if (selectedYear) {
      rv.push({
        dataKey: (d: ChartDataShape<TopYearlyComparison>) =>
          d.data.comparison?.value,
        metaKey: (d: TopYearlyComparison) => d.comparison,
        fill: colorScheme.colors.comparisonWhite,
        activeFill: emphasize(colorScheme.colors.comparisonWhite),
        style: { transform: 'translate(-2%, 0px)' },
      });
    }
    return rv;
  }, [selectedYear]);

  const axisProps = useYAxis(domain);

  let content: React.ReactNode;
  if (error) {
    content = <div />;
  } else if (loading) {
    content = <Loading />;
  } else {
    content = (
      <BarChart
        height={250}
        data={chartData!}
        series={series}
        xAxisType="category"
        yAxis={
          <YAxis
            {...axisProps}
            tick={{
              style: undefined,
              fill: color.text.dark,
            }}
          />
        }
        xAxis={<XAxis height={50} type="category" tick={<TooltipAxisTick />} />}
        getTooltipContent={({ value, data: dataShape, metaKey }) => {
          if (!dataShape || error) return null;
          const meta: YearlyTopDataShape = metaKey && metaKey(dataShape.data);
          if (!meta) return null;
          const percent = meta.percentOfTotal;
          return (
            <>
              <Typography className={tooltipTotal}>
                {numeral(value).format('$0,0')} Total
              </Typography>
              <Typography>
                {numeral(percent).format('"0%"')} of all {dataPoint}
              </Typography>
              <Typography>{meta?.year}</Typography>
            </>
          );
        }}
      />
    );
  }

  const track = useTrackComponent(`Significant Revenue - ${trackingName}`);

  return (
    <>
      <Title
        text={{
          left: <span style={{ padding: '58px' }}>{title}</span>,
          right: `${currentYear} YTD`,
        }}
      />
      <Box onMouseOver={track} onClick={track}>
        {content}
        <ComparisonButtonTabBar
          size="small"
          years={[...populatedYears]}
          selectedYear={selectedYear}
          onSelect={onYearChange}
        />
      </Box>
    </>
  );
}

export default YearlyTopBarChart;
