import { gql, useQuery } from '@apollo/client';
import { useCumulativeDomain } from '@edgeco/react-components';
import { useMemo } from 'react';
import { GetSelectedPdDataQuery } from '../@types';

export const GET_PRODUCTION_BREAKDOWN = gql`
  query getSelectedPdData($repIds: [Int!]!, $years: [Int!]) {
    selectedReps @client @export(as: "repIds")
    viewer {
      aggregateProductionBreakdownByYear(
        repIds: $repIds
        where: { year: { in: $years } }
      ) {
        repIds
        year
        payments {
          Commissions: commissions
          Direct: direct
          Fees: fees
        }
        quarter {
          name
          isComplete: isCompleteUtc
        }
      }
    }
  }
`;

const testData: any[] = [];
const testViewers: any[] = [];

(window as any).testData = testData;
(window as any).testViewers = testViewers;

export const useProductionBreakdownComparisonChart = (
  years: number[],
  currentYear: number,
  selectedYear?: number
) => {
  const { data, loading, error } = useQuery<GetSelectedPdDataQuery>(
    GET_PRODUCTION_BREAKDOWN,
    {
      variables: {
        years: [...years, currentYear],
      },
    }
  );

  testData.push(data);
  testViewers.push(data);

  const allData = useMemo((): ProductionBreakdownChartDataShape[] => {
    if (!data?.viewer?.aggregateProductionBreakdownByYear) return [];
    return data.viewer.aggregateProductionBreakdownByYear?.map((r) => ({
      key: r.quarter.name,
      data: {
        Commissions: r.payments.Commissions,
        Direct: r.payments.Direct,
        Fees: r.payments.Fees,
      },
      metadata: {
        year: r.year,
        isComplete: r.quarter.isComplete,
      },
    }));
  }, [data]);

  const filteredData = useMemo(() => {
    if (!allData) return [] as typeof allData;
    let rv = allData.filter((d) => d.metadata.year === currentYear);
    if (selectedYear) {
      rv = rv.concat(allData.filter((d) => d.metadata.year === selectedYear));
    }
    return rv;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData, currentYear, selectedYear]);

  const domain = useCumulativeDomain(filteredData, data?.selectedReps);

  const populatedYears = useMemo(
    () => years.filter((y) => allData?.some((d) => d.metadata.year === y)),
    [allData, years]
  );

  return {
    productionBreakdownChartData: filteredData,
    populatedYears,
    domain,
    loading,
    error,
  };
};
