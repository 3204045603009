import { useEffect, useState } from 'react';
import { KeyValueTypes } from '@edgeco/react-components';

import { useTopPayoutAccountsChart } from 'edgeco/graphql/advisor-summary/queries';
import PeriodTopDonutChart from './PeriodTopDonutChart';

type Props = {
  onTopAccountsLoaded: (payload: KeyValueTypes[]) => void;
};

export default function TopPayoutAccounts({ onTopAccountsLoaded }: Props) {
  const [datePeriod, setDatePeriod] =
    useState<TopPayoutAccountDatePeriod>('Month');
  const { topPayoutAccountsChartData, loading, error, totalPercent } =
    useTopPayoutAccountsChart(datePeriod);

  useEffect(() => {
    const accountKeyOnly = topPayoutAccountsChartData.map((x) => x.key);
    onTopAccountsLoaded(accountKeyOnly);
  }, [topPayoutAccountsChartData, onTopAccountsLoaded]);

  return (
    <>
      <PeriodTopDonutChart
        title="Top 5 Revenue"
        trackingName="TopPayout"
        chartData={topPayoutAccountsChartData}
        loading={loading}
        error={error}
        totalPercent={totalPercent}
        datePeriod={datePeriod}
        setDatePeriod={setDatePeriod}
      />
    </>
  );
}
