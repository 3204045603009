import { gql } from '@apollo/client';
import useAuth from 'edgeco/hooks/useAuth';
import {
  RemoveReportFromUserMutation,
  RemoveReportFromUserMutationVariables,
} from '../../@types';
import { useUserProfileMutation } from '../useUserProfileMutation';

const REMOVE_USER_REPORT = gql`
  mutation RemoveReportFromUser($reportId: UUID!, $userId: UUID!) {
    RemoveReportFromUser(input: { reportId: $reportId, userId: $userId })
  }
`;

export const useRemoveUserReport = () => {
  const { accountInfo } = useAuth();
  const userId = accountInfo?.account?.accountIdentifier!;
  const [removeReport, { loading }] = useUserProfileMutation<
    RemoveReportFromUserMutation,
    RemoveReportFromUserMutationVariables
  >(REMOVE_USER_REPORT);
  return {
    removeReport: (reportId: string) => {
      removeReport({
        variables: {
          userId,
          reportId,
        },
        update(cache) {
          cache.modify({
            fields: {
              viewer(existingViewer: any, { readField }: any) {
                const reports = existingViewer.reports.filter(
                  (r: any) => readField('id', r).identifier !== reportId
                );
                return { ...existingViewer, reports };
              },
            },
          });
        },
      });
    },
    loading,
  };
};
