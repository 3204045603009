import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

export interface ExtendedRouteProps {
  exclusive?: boolean;

  routes?: ExtendedRoute[];

  location?: Location;

  component: React.ComponentType<any>;

  render?: (props: RouteComponentProps<any>) => React.ReactNode;

  children?: React.ReactChildren;

  path?: string | string[];

  exact?: boolean;

  sensitive?: boolean;

  strict?: boolean;
}

export class ExtendedRoute {
  constructor({
    exclusive = false,

    routes = [],

    location,

    component,

    render,

    children,

    path,

    exact,

    sensitive,

    strict,
  }: ExtendedRouteProps) {
    this.routes = routes;

    this.location = location;

    this.Component = component;

    this.render = render;

    this.children = children;

    this.path = path;

    this.exact = exact;

    this.sensitive = sensitive;

    this.strict = strict;

    this.exclusive = exclusive;
  }

  routes: ExtendedRoute[];

  exclusive: boolean;

  location?: Location;

  Component: React.ComponentType<any>;

  render?: (props: RouteComponentProps<any>) => React.ReactNode;

  children?:
    | ((props: RouteChildrenProps<any>) => React.ReactNode)
    | React.ReactNode;

  path?: string | string[];

  exact?: boolean;

  sensitive?: boolean;

  strict?: boolean;
}

export default ExtendedRoute;
