import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { FilterOperator } from '@edgeco/react-components';
import { GetTransactionTotalsQuery } from '../@types';

export const GET_PRODUCTION_DETAIL_TOTALS = gql`
  query GetTransactionTotals(
    $repIds: [Int!]!
    $startDate: DateTime!
    $endDate: DateTime!
    $revenueCategories: [String!]!
  ) {
    selectedReps @client @export(as: "repIds")
    viewer {
      transactionTotalsByRepId(
        repIds: $repIds
        startDate: $startDate
        endDate: $endDate
        revenueCategories: $revenueCategories
      ) {
        gross
        net
        maClearing
      }
    }
  }
`;

export const useProductionDetailTotals = (filters: FilterRequestFilter[]) => {
  // TODO: Update this GQL endpoint to use built in HotChocolate aggregation once it is released
  // https://github.com/ChilliCream/hotchocolate/issues/924
  const startDate = filters.find(
    (f) =>
      f.operator === FilterOperator.GreaterThanOrEqual &&
      f.property === 'transDate'
  )?.value as string;
  const endDate = filters.find(
    (f) =>
      f.operator === FilterOperator.LessThanOrEqual &&
      f.property === 'transDate'
  )?.value as string;
  const revenueCategories = filters.find(
    (f) =>
      f.operator === FilterOperator.Contains && f.property === 'revenueCategory'
  )?.value as string[];

  const skip = !startDate || !endDate;

  const { data, loading, error } = useQuery<GetTransactionTotalsQuery>(
    GET_PRODUCTION_DETAIL_TOTALS,
    {
      fetchPolicy: skip ? 'cache-only' : 'cache-first',
      variables: {
        startDate,
        endDate,
        revenueCategories,
      },
    }
  );

  const result = useMemo(() => {
    if (!data) return undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { __typename, ...rest } =
      data?.viewer?.transactionTotalsByRepId ?? {};
    return rest;
  }, [data]);

  return {
    data: result as ProductionDetail,
    loading: loading && !skip,
    error,
  };
};
