import { Redirect } from 'react-router';

import { FeatureFlag } from '@edgeco/react-components';
import { Feature } from 'edgeco/types/feature';

import ExtendedRoute, { ExtendedRouteProps } from './ExtendedRoute';

type FeatureRoutProps = ExtendedRouteProps & {
  feature: Feature;
};

export class FeatureRoute extends ExtendedRoute {
  constructor(props: FeatureRoutProps) {
    super(props);

    const ArgsComponent = props.component;

    this.Component = () => (
      <>
        <FeatureFlag name={props.feature}>
          <ArgsComponent />
        </FeatureFlag>

        <FeatureFlag name={props.feature} showIfDisabled>
          <Redirect to="/error/404" />
        </FeatureFlag>
      </>
    );
  }
}
