import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useEffectAsync,
  useMeasure,
  useScrollbarSize,
  buildListMap,
  TransferListMap,
  StickLeft,
} from '@edgeco/react-components';
import {
  useTable,
  getVisibleColumns,
  FlexTableFooter,
  TableFilters,
  Table,
  TableActions,
  useCreateFilterContext,
  useFilterReducer,
  useCreateTableContext,
} from 'edgeco/components/data-visualization';
import zIndex from '@material-ui/core/styles/zIndex';
import { makeStyles } from '@material-ui/core';
import { TableState } from 'react-table';
import produce from 'immer';
import { ProductionDetailFilter } from 'edgeco/components/routing';
import { PageWrapper } from 'edgeco/components/root';
import { useProductionTransactionsTable } from 'edgeco/graphql/advisor-summary/queries';
import { useProductionTransactionsTableExport } from 'edgeco/graphql/advisor-summary/queries/productionDetailTransactions';
import { useProductionDetailTotals } from 'edgeco/graphql/advisor-summary/queries/productionDetailTotals';
import { useAddProductionDetailReport } from 'edgeco/graphql/user-profile/mutations/user/addUserReport';
import { SavedFilters } from 'edgeco/components/data-visualization/Table/Filters/SavedFilters';
import { useQueryParams } from 'edgeco/hooks/useQueryParams';
import ProductionDetailSubComponent from './ProductionDetailSubComponent';

import {
  columnDefinitions,
  getFiltersRequest,
  reportFilters,
  revenueCategoryNameToEnumMap,
} from './definitions';
import PageFooter from '../../components/root/PageFooter';

const useStyles = makeStyles<EdgeCoTheme, { viewWidth: number }>(
  ({ spacing, extensions }) => ({
    root: {
      flexDirection: 'column',
      width: 'max-content',
    },
    tableActions: {
      top: 0,
      position: 'sticky',
      zIndex: zIndex.appBar + 10,
      backgroundColor: extensions.color.background,
      paddingTop: 24,
      width: '100%',
    },
    tableContainer: {
      flex: 1,
    },
    table: {
      maxHeight: '100%',
    },
    filterContainer: {
      background: extensions.color.backgroundContrast,
      margin: `0 ${spacing(3)}px`,
    },
    filter: {
      maxWidth: 1200,
    },
    pageWrapperContainer: {
      width: 'max-content',
      maxWidth: 'none',
    },
    pageWrapperContent: {
      width: 'max-content',
    },
    pageWrapperHeader: {
      padding: spacing(0, extensions.pageWrapper.spacing.padding),
      marginBottom: 0,
    },
    pageFooter: {
      marginTop: 0,
      maxWidth: 'none',
    },
    pageFooterContent: {
      maxWidth: 'none',
    },
  })
);

const requiredColumns: ProductionDetailKey[] = [
  'transId',
  'revenueCategory',
  'transDate',
  'rr2',
];

const defaultColumns: ProductionDetailKey[] = [
  'gross',
  'net',
  'payDate',
  'productDescription',
  'accountNumber',
];

const additionalExportColumns: ProductionDetailKey[] = [
  'rr1',
  'period',
  'security',
  'buySell',
  'price',
  'accountBalance',
  'shares',
  'principal',
  'maClearing',
];

const footerColumns: ProductionDetailKey[] = ['gross', 'net', 'maClearing'];

// Defining this here, as it may change per table
const DEFAULT_TABLE_SIZE = 80;
const DEFAULT_TABLE_START_INDEX = 0;

function ProductionDetailView() {
  const { width: viewWidth } = useScrollbarSize();
  const classes = useStyles({ viewWidth });
  const queryParams = useQueryParams<
    PagedSearch<OwnedRecordParams> & ProductionDetailFilter
  >();
  const { modifyQuery, queryObject } = queryParams;

  const filters = produce(reportFilters, () => {});
  const filterReducer = useFilterReducer({ filters });
  const {
    state: { filterQuery: currentFilterQuery },
  } = filterReducer;
  const filterContext = useCreateFilterContext(filterReducer);
  const { editQuery } = filterContext.value.state;
  const { pageIndex, pageSize } = queryObject;

  const { ref: filterRef, measurement: filterMeasurement } = useMeasure();
  const rootRef = useRef<HTMLDivElement>(null);

  const selectedColumns = React.useMemo<ProductionDetailKey[]>(
    () => [
      // Mandatory columns
      ...requiredColumns,

      // Default columns
      ...defaultColumns,
    ],
    []
  );

  // https://github.com/Microsoft/TypeScript/pull/12253#issuecomment-263132208
  // https://stackoverflow.com/questions/55012174/why-doesnt-object-keys-return-a-keyof-type-in-typescript
  // Object.keys(TObject) will not return keyof TObject, even with generics
  const allColumns: ProductionDetailKey[] = useMemo(
    () => Object.keys(columnDefinitions) as ProductionDetailKey[],
    []
  );

  const initialTableState: Partial<TableState<ProductionDetail>> = useMemo(
    () => ({
      hiddenColumns: allColumns.filter(
        (x) =>
          !selectedColumns.includes(x) &&
          columnDefinitions[x]?.hidden !== 'always'
      ), // Hide all but selected & always hidden columns
    }),
    [allColumns, selectedColumns]
  );

  const { updateTableData, tableInstance, subComponent, setLoading } = useTable(
    {
      columns: allColumns,
      columnDefinitions,
      initialSelectedColumns: selectedColumns,
      initialState: initialTableState,
      subComponent: ProductionDetailSubComponent,
    }
  );

  const { addReportsToUser } = useAddProductionDetailReport();

  const {
    gotoPage,
    state: { sortBy },
    visibleColumns,
  } = tableInstance;

  const mapFiltersToQuery: FilterRequestFilter[] = useMemo(() => {
    tableInstance.toggleAllRowsExpanded(false);
    return getFiltersRequest(currentFilterQuery);
  }, [currentFilterQuery, tableInstance]);

  const updatePageIndex = useCallback(
    (gotoIndex: number) => {
      gotoPage(gotoIndex);
    },
    [gotoPage]
  );

  const [columnListDefinition, setColumnListDefinition] = useState(() =>
    buildListMap(getVisibleColumns(columnDefinitions))
  );
  const [columnListMap, setColumnListMap] = useState<TransferListMap>();

  const { value: tableContext, Provider: TableContextProvider } =
    useCreateTableContext({
      requiredColumns,
      tableInstance,
      filterDefinition: reportFilters,
      onSaveFilters: (filterName: string) => {
        const visibleColumnIds = visibleColumns.map((c) => c.id as string);
        const revenueCategories = [
          ...editQuery.Commissions,
          ...editQuery.Direct,
          ...editQuery.Fees,
          ...editQuery.Expenses,
        ].map((x) => revenueCategoryNameToEnumMap[x]);
        addReportsToUser(filterName, revenueCategories, visibleColumnIds);
      },
      columnListMap,
      setColumnListMap,
      columnListDefinition,
      setColumnListDefinition,
    });
  const index = pageIndex ?? DEFAULT_TABLE_START_INDEX;

  const requestSortBy = sortBy.length === 0 ? [{ id: 'transDate' }] : sortBy;

  const { pagedTransactionRecords, loading: loadingRows } =
    useProductionTransactionsTable(
      mapFiltersToQuery,
      allColumns,
      requestSortBy,
      pageSize ?? DEFAULT_TABLE_SIZE,
      index
    );

  const { data: totalData, loading: loadingTotals } =
    useProductionDetailTotals(mapFiltersToQuery);
  const exportColumns = useMemo(() => {
    return [
      ...tableInstance.visibleColumns
        .map((c) => c.id)
        .filter((id) => id !== 'expander'),
      ...additionalExportColumns,
    ];
  }, [tableInstance.visibleColumns]);

  const loading = loadingTotals || loadingRows;
  const { fetchData: fetchExportData } = useProductionTransactionsTableExport(
    mapFiltersToQuery,
    exportColumns,
    requestSortBy
  );
  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffectAsync(async () => {
    if (loading) return;
    const data = pagedTransactionRecords;

    if (data.page && data.page.pageCount - 1 < index) {
      modifyQuery(['pageIndex', data.page.pageCount - 1]);

      updatePageIndex(data.page.pageCount - 1);
      return;
    }

    updateTableData(data);
    updatePageIndex(data.page?.pageIndex ?? DEFAULT_TABLE_START_INDEX);
  }, [
    index,
    loading,
    modifyQuery,
    pagedTransactionRecords,
    updatePageIndex,
    updateTableData,
  ]);

  return (
    <TableContextProvider value={tableContext}>
      <filterContext.Provider value={filterContext.value}>
        <PageWrapper
          sticky={true}
          title={'Production Detail Report'}
          paddingLeft={0}
          paddingRight={0}
          classes={{
            container: classes.pageWrapperContainer,
            content: classes.pageWrapperContent,
            header: classes.pageWrapperHeader,
          }}
        >
          <div className={classes.root} ref={rootRef}>
            <div className={classes.tableActions} ref={filterRef}>
              <SavedFilters />
              <StickLeft>
                <div>
                  <div className={classes.filterContainer}>
                    <TableFilters />
                  </div>
                  <TableActions
                    instance={tableInstance}
                    exportProps={{
                      fetchData: fetchExportData,
                      fileName: 'MACG-WM-ProductionDetail',
                      additionalColumns: additionalExportColumns,
                    }}
                  />
                </div>
              </StickLeft>
            </div>
            <div className={classes.tableContainer}>
              <Table
                className={classes.table}
                instance={tableInstance}
                subComponent={subComponent}
                stickyTop={
                  filterMeasurement
                    ? filterMeasurement?.contentRect.height +
                      filterMeasurement?.contentRect.y
                    : 0
                }
              />
            </div>
            {!loading && (
              <FlexTableFooter
                sticky={true}
                data={totalData}
                loading={loadingTotals}
                columns={footerColumns}
                columnDefinitions={columnDefinitions}
              />
            )}
            <StickLeft>
              <PageFooter
                classes={{
                  root: classes.pageFooter,
                  content: classes.pageFooterContent,
                }}
              />
            </StickLeft>
          </div>
        </PageWrapper>
      </filterContext.Provider>
    </TableContextProvider>
  );
}

export default ProductionDetailView;
