import moment from 'moment';
import {
  REQUIRED_GROUP,
  FilterOperator,
  getDateFilters,
  getPastYears,
  FilterQueryObject,
  TableFilterCollection,
  DateTableFilterOption,
  DateTableFilter,
  ColumnDefinitionList,
} from '@edgeco/react-components';

// TODO: Once design is finalized we will need a list of all column formats
// Note: This could be completely data driven and loaded from an api
export const columnDefinitions: ColumnDefinitionList<ProductionDetail> = {
  revenueCategory: {
    header: 'Revenue Category',
    sticky: 'left',
    group: REQUIRED_GROUP,
    minWidth: 200,
  },
  transDate: {
    header: 'TransDate',
    sticky: 'left',
    type: 'date',
    group: REQUIRED_GROUP,
    minWidth: 115,
  },
  rr2: {
    header: 'Rep ID',
    sticky: 'left',
    group: REQUIRED_GROUP,
    minWidth: 80,
  },
  transId: { header: 'TransId', group: REQUIRED_GROUP },

  // payment info
  gross: {
    header: 'Gross',
    footer: 'Gross Total',
    format: '$0,0.00',
    group: 'Payment Information',
    isSortable: true,
  },
  repPayoutPercent: { header: '% Rep Payout', group: 'Payment Information' },
  net: {
    header: 'Net',
    footer: 'Net Total',
    format: '$0,0.00',
    group: 'Payment Information',
    isSortable: true,
  },
  payDate: {
    header: 'Pay Date',
    type: 'date',
    group: 'Payment Information',
  },
  paymentNotes: { header: 'Payment Notes', group: 'Payment Information' },
  originalToBePaidDate: {
    header: 'Original to Be Paid',
    group: 'Payment Information',
    type: 'date',
  },
  payTo: { header: 'Pay To', group: 'Payment Information' },
  paymentMethod: { header: 'Payment Method', group: 'Payment Information' },

  // product info
  productDescription: {
    header: 'Product Description',
    group: 'Product Information',
    width: 215,
    tooltip: true,
  },
  productCode: { header: 'Product Code', group: 'Product Information' },
  productType: { header: 'Product Type', group: 'Product Information' },

  // account info
  accountNumber: {
    header: 'Account No',
    group: 'Account Information',
    isSortable: true,
    tooltip: true,
    tooltipWidth: 1000,
    width: 169,
  },

  shortName: { header: 'Short Name', group: 'Account Information' },
  accountType: { header: 'Account Type', group: 'Account Information' },
  client: { header: 'Client Name', group: 'Account Information' },
  householdName: {
    header: 'Household Name',
    group: 'Account Information',
    isSortable: true,
  },

  // misc
  accountBalance: { header: 'Account Balance', hidden: 'always' },
  security: { header: 'Security', hidden: 'always' },

  // transaction details
  rr1: { header: 'RR1', hidden: 'always' },
  buySell: { header: 'Buy/Sell', hidden: 'always' },
  price: { header: 'Prices', hidden: 'always' },
  principal: { header: 'Principal', hidden: 'always' },
  period: { header: 'Period', hidden: 'always' },
  shares: { header: 'Shares', hidden: 'always' },
  maClearing: {
    header: 'MA Clearing',
    hidden: 'always',
    footer: 'MA Clearing Total',
    format: '$0,0.00',
  },
};
export type FilterQuery = FilterQueryObject<{
  Commissions: string[];
  Direct: string[];
  Fees: string[];
  Expenses: string[];
}>;

// TODO: encode parameter request
export const getRevenueCategoryFilters = (
  values: (string | number)[]
): FilterRequestFilter => {
  return {
    operator: FilterOperator.Contains,
    value: values,
    property: 'revenueCategory',
  } as FilterRequestFilter;
};

export const getFiltersRequest = (
  query: FilterQuery
): FilterRequestFilter[] => {
  const {
    endDate,
    startDate,
    datePeriod,
    Commissions,
    Direct,
    Fees,
    Expenses,
  } = query;
  const dateFilters: FilterRequestFilter[] = getDateFilters(
    'TransDate',
    datePeriod,
    startDate,
    endDate
  );
  const revenueFilters = getRevenueCategoryFilters([
    ...Commissions,
    ...Direct,
    ...Fees,
    ...Expenses,
  ]);

  return [...dateFilters, ...[revenueFilters]];
};
// Note: This could be completely data driven and loaded from an api
export const reportFilters: TableFilterCollection = [
  {
    kind: 'group',
    title: 'Filter by Revenue Category',
    breakpoints: {
      group: {
        sm: 12,
        md: 9,
        lg: 10,
      },
      filters: {
        xs: 6,
        sm: 3,
      },
    },
    filters: [
      {
        title: 'Commissions',
        searchParam: 'Commissions',
        field: 'revenueCategory',
        options: [
          { value: 'Brokerage Commissions' },
          { value: 'Override Payment - MACC' },
        ],
        multiple: true,
      },
      {
        title: 'Fees',
        searchParam: 'Fees',
        field: 'revenueCategory',
        options: [
          { value: 'ADV CH NFS' },
          { value: 'ADV CH TNS' },
          { value: 'MAFS Fees' },
          { value: 'MAPS Fees' },
          { value: 'Misc Revenue - MACC' },
          { value: 'Misc Revenue - MAFM' },
          { value: 'Override Payment - MAFM' },
        ],
        multiple: true,
      },
      {
        title: 'Direct',
        searchParam: 'Direct',
        field: 'revenueCategory',
        options: [
          { value: 'Annuities' },
          { value: 'Alternative Asset Revenue' },
          { value: 'Mutual Fund (12b-1)' },
          { value: 'Mutual Fund (Comm)' },
          { value: 'Mutual Fund (NFS)' },
        ],
        multiple: true,
      },
      {
        title: 'Expenses',
        searchParam: 'Expenses',
        field: 'revenueCategory',
        options: [
          { value: 'Misc Costs - MACC' },
          { value: 'Misc Costs - MAFM' },
          { value: 'Advisor Technology Billing' },
        ],
        multiple: true,
      },
    ],
  },
  {
    kind: 'group',
    title: 'Filter by Date',
    breakpoints: {
      group: {
        xs: 12,
        md: 3,
        lg: 2,
      },
      filters: {
        xs: 6,
        sm: 3,
        md: 12,
      },
    },
    filters: [
      {
        kind: 'date',
        title: '',
        options: [
          { datePeriod: 'Yesterday' },
          { datePeriod: 'MTD', label: 'Month to Date' },
          { datePeriod: 'YTD', label: 'Year to Date', showQuarters: true },
          { datePeriod: 'T12', label: 'Last 12 Months' },
          ...getPastYears(5, false)
            .reverse()
            .map<DateTableFilterOption>((year) => ({
              startDate: moment().year(year).startOf('year'),
              endDate: moment().year(year).endOf('year'),
              label: year.toString(),
              showQuarters: true,
            })),
        ],
      } as DateTableFilter,
    ],
  },
];

// Note: Used for mapping ENUMS from the API to acceptible values used in query params
export const revenueCategoryEnumToNameMap: [string, object][] = [
  [
    'Commissions',
    {
      BROKERAGE_COMMISSIONS: 'Brokerage Commissions',
      OVERRIDE_PAYMENT_MACC: 'Override Payment - MACC',
    },
  ],
  [
    'Fees',
    {
      ADV_CH_NFS: 'ADV CH NFS',
      ADV_CH_TNS: 'ADV CH TNS',
      MAFS_FEES: 'MAFS Fees',
      MAPS_FEES: 'MAPS Fees',
      MISC_REVENUE_MACC: 'Misc Revenue - MACC',
      MISC_REVENUE_MAFM: 'Misc Revenue - MAFM',
      OVERRIDE_PAYMENT_MAFM: 'Override Payment - MAFM',
    },
  ],
  [
    'Direct',
    {
      ANNUITIES: 'Annuities',
      ALTERNATIVE_ASSET_REVENUE: 'Alternative Asset Revenue',
      MUTUAL_FUND12_B1: 'Mutual Fund (12b-1)',
      MUTUAL_FUND_COMM: 'Mutual Fund (Comm)',
      MUTUAL_FUND_NFS: 'Mutual Fund (NFS)',
    },
  ],
  [
    'Expenses',
    {
      MISC_COSTS_MACC: 'Misc Costs - MACC',
      MISC_COSTS_MAFM: 'Misc Costs - MAFM',
      ADVISOR_TECHNOLOGY_BILLING: 'Advisor Technology Billing',
    },
  ],
];

export const revenueCategoryNameToEnumMap = revenueCategoryEnumToNameMap.reduce(
  (resultMap, [, mapping]) => {
    const categoryMap = Object.entries(mapping).reduce(
      (accInner, [key, value]) => ({ ...accInner, [value]: key }),
      {} as any
    );
    return { ...resultMap, ...categoryMap };
  },
  {} as any
);
