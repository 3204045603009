import React, { useEffect } from 'react';
import { createStyles, Link, Typography, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { RouteChildrenProps, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { namedColors, useEffectAsync } from '@edgeco/react-components';

import { useTrackComponent } from 'edgeco/hooks/AppInsights';
import useAuth from 'edgeco/hooks/useAuth';
import { cachePersistor } from 'edgeco/graphql/cache';

import { setPostLogoutRedirectUri } from '../../libs/config/oidcConfig';

const styles = ({
  extensions: { color },
  typography: { pxToRem },
  breakpoints,
  spacing,
}: EdgeCoTheme) =>
  createStyles({
    centerVertical: {
      display: 'flex',
      alignItems: 'center',
    },
    oops: {
      fontSize: pxToRem(200),
      [breakpoints.down('xs')]: {
        fontSize: pxToRem(100),
      },
    },
    code: {
      fontSize: pxToRem(280),
      lineHeight: 0.75,
      [breakpoints.down('xs')]: {
        fontSize: pxToRem(160),
      },
    },
    codeCaption: {
      fontSize: pxToRem(36),
      fontWeight: 'bold',
      textAlign: 'center',
      color: namedColors.tundora,
      [breakpoints.down('xs')]: {
        fontSize: pxToRem(25),
      },
    },
    dividerContainer: {
      fontSize: pxToRem(200),
      marginLeft: 45,
      marginRight: 62,
      display: 'flex',
      alignItems: 'center',
      [breakpoints.down('sm')]: {
        margin: spacing(0, 2),
      },
      [breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    divider: {
      width: 33,
      height: 290,
      background: color.primary,
    },
    codeContainer: {
      paddingTop: 28,
    },
    descriptionContainer: {
      paddingTop: 18,
    },
    supportNumber: {
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },
  });

type Props = WithStyles<typeof styles> &
  RouteChildrenProps<{
    errorCode?: string;
  }>;

function ErrorView({ classes, match }: Props) {
  const auth = useAuth();
  const location = useLocation();
  const errorCode = match?.params.errorCode;
  const apolloClient = useApolloClient();
  const track = useTrackComponent(`Error - ${errorCode}`);
  useEffect(() => track(), [track]);

  useEffectAsync(async () => {
    if (errorCode && parseInt(errorCode) === 401) {
      await cachePersistor.purge();
      await apolloClient.resetStore();
    }
  }, [apolloClient, errorCode]);

  const handleLogOut = () => {
    // prevent us from redirecting back to the 401 page immediately after logging out
    setPostLogoutRedirectUri(location.state?.from || window.location.origin);
    auth.logout();
  };

  const supportNumber = (
    <span className={classes.supportNumber}>412-745-5454</span>
  );

  const dashboardLink = (
    <div>
      <Link component={RouterLink} to="/dashboard">
        Go back to dashboard page
      </Link>
    </div>
  );

  const errorCodeMap: Record<string, [string, React.ReactNode]> = {
    '401': [
      'NOT AUTHORIZED',
      <div style={{ maxWidth: 690 }}>
        You are not authorized to access this page. Please call our customer
        service desk at: {supportNumber}. Or,{' '}
        <Link onClick={handleLogOut}>try logging in again.</Link>
      </div>,
    ],
    '403': [
      'FORBIDDEN',
      <div style={{ maxWidth: 690 }}>
        You are forbidden to access this page. Please call our customer service
        desk at: {supportNumber}.
      </div>,
    ],
    '404': [
      'NOT FOUND',
      <div style={{ maxWidth: 640 }}>
        We cannot find the page you are looking for. {dashboardLink}
      </div>,
    ],
  };

  const isCodeValid = errorCode && errorCodeMap[errorCode] !== undefined;

  const [codeCaption, description] = isCodeValid
    ? errorCodeMap[errorCode!]
    : [
        '',
        <div style={{ maxWidth: 550 }}>
          Something went wrong! Please call our customer service desk at:{' '}
          {supportNumber}. {dashboardLink}
        </div>,
      ];

  return (
    <>
      <div className={clsx(classes.centerVertical, classes.codeContainer)}>
        <div>
          <div className={isCodeValid ? classes.code : classes.oops}>
            {isCodeValid ? errorCode : 'Oops!'}
          </div>
          {<div className={classes.codeCaption}>{codeCaption}</div>}
        </div>
      </div>
      <div className={classes.centerVertical}>
        <div className={classes.dividerContainer}>
          <div className={classes.divider} />
        </div>
        <div
          className={clsx(classes.centerVertical, classes.descriptionContainer)}
        >
          <div>
            <Typography variant="h2">Terribly sorry but...</Typography>
            <Typography>{description}</Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default withStyles(styles)(ErrorView);
