import {
  MsalAuthProvider,
  LoginType,
  IMsalAuthProviderConfig,
} from 'react-aad-msal';
import {
  Configuration,
  LogLevel,
  Logger,
  AuthenticationParameters,
} from 'msal';
import appConfig from './appConfig';

const logger = new Logger(
  (_, message) => {
    // eslint-disable-next-line no-console
    console.log('[MSAL]', message);
  },
  {
    level: LogLevel.Warning,
    piiLoggingEnabled: false,
  }
);

type PostLogoutRedirectUri = string | (() => string);

let postLogoutRedirectUri: PostLogoutRedirectUri = () =>
  `${window.location.origin}/logged-out`;

export const setPostLogoutRedirectUri = (postLogout: PostLogoutRedirectUri) => {
  postLogoutRedirectUri = postLogout;
};

// Msal Configurations
const config: Configuration = {
  system: {
    logger: logger as any,
  },
  auth: {
    authority: `${appConfig.oidcAuthority}/${appConfig.oidcTenant}/${appConfig.oidcFlow}`,
    clientId: appConfig.oidcClient,
    redirectUri: appConfig.oidcRedirect,
    validateAuthority: false,
    postLogoutRedirectUri: () =>
      typeof postLogoutRedirectUri === 'string'
        ? postLogoutRedirectUri
        : postLogoutRedirectUri(),
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

// There are possible issues with offline_access being included in access_token requests.  B2C needs offline_access at the moment, so it's added later
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1932

// Available scopes for access token requests
export const accessScopes = {
  openid: 'openid',
  profile: 'profile',
  summaryApi: `https://${appConfig.oidcTenant}/summary/read`,
  userProfileApi: `https://${appConfig.oidcTenant}/wm-gql-gateway/read`,
} as const;

export type AccessScopes = typeof accessScopes[keyof typeof accessScopes];
// Authentication Parameters
const authenticationParameters: AuthenticationParameters = {
  scopes: Object.values<string>(accessScopes).concat('offline_access'),
};

// Options
const options: IMsalAuthProviderConfig = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: `${window.location.origin}/auth.html`,
};

// MsalAuthProvider extends UserAgenApplication from Msal
// Bugs can happen when there's more than 1 instance
export const auth = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);

auth.registerErrorHandler((error) => {
  if (error && error.errorMessage.indexOf('AADB2C90118') > -1) {
    auth.login({
      authority: `${appConfig.oidcAuthority}/${appConfig.oidcTenant}/${appConfig.oidcResetPasswordFlow}`,
    });
  }
});
