import {
  AppBar,
  Link as MuiLink,
  Slide,
  useScrollTrigger,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import { FeatureFlag, namedColors } from '@edgeco/react-components';

import appConfig from 'edgeco/config/appConfig';
import useAuth from 'edgeco/hooks/useAuth';

import { isMobile } from 'react-device-detect';
import { ReactComponent as Logo } from '../../assets/imgs/wm_beta_logo.svg';
import { ReactComponent as LogoQa } from '../../assets/imgs/wm_qa_logo.svg';
import { ReactComponent as LogoUat } from '../../assets/imgs/wm_uat_logo.svg';
import RepSelectionModalButton from '../modal/RepSelectionModalButton';
import { Feature } from '../../@types/feature';

const useStyles = makeStyles(
  ({
    breakpoints,
    extensions: { toolbarHeight, color },
    typography: { pxToRem },
    spacing,
    zIndex,
  }: EdgeCoTheme) => ({
    appBar: {
      zIndex: zIndex.drawer + 1,
      height: toolbarHeight,
      background: color.background,
      boxShadow: `0px 2px 4px 3px rgba(0,0,0,0.2),
                0px 4px 5px 3px rgba(0,0,0,0.14),
                0px 1px 10px 3px rgba(0,0,0,0.12)`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      [breakpoints.down('md')]: {
        height: 65,
      },
    },
    logoContainer: {
      height: toolbarHeight,
      padding: spacing(4.5, 0, 0, 3.5),
      flex: 1,

      [breakpoints.down('md')]: {
        padding: spacing(4.5, 0, 0, 0.5),
      },
    },
    logoutContainer: {
      padding: spacing(0, 3.5, 0, 0),

      [breakpoints.down('md')]: {
        padding: spacing(0, 0, 0, 0),
        marginBottom: 5,
        marginRight: 20,
      },
    },
    logo: {
      height: 65,

      [breakpoints.down('md')]: {
        height: 32,
      },
    },
    logoutLink: {
      fontSize: pxToRem(28),
      fontWeight: 'bold',
      color: namedColors.emperor,
      whiteSpace: 'nowrap',

      [breakpoints.down('md')]: {
        fontSize: '1em',
        whiteSpace: 'nowrap',
      },
    },
    userIcon: {
      width: 45,
      height: 40,
      fill: color.text.dark,
      marginLeft: '0.5rem',

      [breakpoints.down('md')]: {
        width: 44,
        height: 44,
        marginRight: '1.0rem',
        marginBottom: 5,
      },
    },
    mailIcon: {
      width: 45,
      height: 40,
      fill: color.text.dark,

      [breakpoints.down('md')]: {
        display: 'none',
      },
    },
    notificationsContainer: {
      padding: spacing(0, 3.5, 0, 0),

      [breakpoints.down('md')]: {
        padding: spacing(0, 0.0, 0, 0),
      },
    },
  })
);

export default function Topbar() {
  const auth = useAuth();
  const classes = useStyles();
  const visible = !useScrollTrigger({
    disableHysteresis: false,
    threshold: 0,
  });

  // TODO: Update to use same solution as white labeling
  let LogoComponent = Logo;
  if (isMobile) {
    LogoComponent = Logo;
  } else {
    switch (appConfig.deployEnv) {
      case 'production': {
        LogoComponent = Logo;
        break;
      }
      case 'uat':
        LogoComponent = LogoUat;
        break;
      default:
        LogoComponent = LogoQa;
        break;
    }
  }

  return (
    <Slide appear={false} in={visible} timeout={{ enter: 225, exit: 195 }}>
      <AppBar position={'fixed'} className={classes.appBar}>
        <div className={classes.logoContainer}>
          <Link to="/dashboard">
            <LogoComponent className={classes.logo} />
          </Link>
        </div>
        <div className={classes.notificationsContainer}>
          {isMobile ? (
            <RepSelectionModalButton />
          ) : (
            <>
              <FeatureFlag name={Feature.Notifications}>
                <EmailIcon className={classes.mailIcon} fontSize="large" />
              </FeatureFlag>
              <FeatureFlag name={Feature.ProfilePage}>
                <Link to="/profile">
                  <AccountCircleIcon className={classes.userIcon} />
                </Link>
              </FeatureFlag>
            </>
          )}
        </div>
        <div className={classes.logoutContainer}>
          <MuiLink
            underline={'none'}
            className={classes.logoutLink}
            onClick={() => auth.logout()}
          >
            Log Out
          </MuiLink>
        </div>
      </AppBar>
    </Slide>
  );
}
