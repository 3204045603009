export type RoutingFilterValue = string[] | string;

export type ProductionDetailFilter = Partial<
  Record<ProductionCategory, RoutingFilterValue>
> & {
  datePeriod?: RoutingFilterValue;
  startDate: Date;
  endDate?: Date;
};

export enum ProductionDetailLinkType {
  DetailLink,
  ReviewButton,
  Custom,
}
