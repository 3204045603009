import { gql, useQuery } from '@apollo/client';
import { ToutData } from '@edgeco/react-components';
import { GetAdvisorHighlightsQuery } from '../@types';

export const GET_ADVISOR_ACCOUNT_HIGHLIGHTS = gql`
  query GetAdvisorHighlights($repIds: [Int!]!) {
    selectedReps @client @export(as: "repIds")
    viewer {
      aggregateRepHighlights(repIds: $repIds) {
        title
        description
        data {
          format
          value
        }
      }
    }
  }
`;

export const useAdvisorHighlightsTouts = () => {
  const { data, loading, error } = useQuery<GetAdvisorHighlightsQuery>(
    GET_ADVISOR_ACCOUNT_HIGHLIGHTS
  );
  const advisorHightlightsToutData = (data?.viewer?.aggregateRepHighlights ??
    []) as ToutData[];
  return { advisorHightlightsToutData, loading, error };
};
