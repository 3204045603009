import { gql, useQuery } from '@apollo/client';
import {
  useCumulativeDomain,
  FiscalQuarterValues,
  ChartDataShape,
  getPastYears,
} from '@edgeco/react-components';

import { useMemo } from 'react';

import { GetViewerProductionHistoryQuery } from '../@types';

export const GET_PRODUCTION_HISTORY = gql`
  query GetViewerProductionHistory(
    $repIds: [Int!]!
    $yearStart: Int
    $yearEnd: Int
  ) {
    selectedReps @client @export(as: "repIds")
    viewer {
      aggregateProductionHistoryByYear(
        repIds: $repIds
        where: { year: { gte: $yearStart, lte: $yearEnd } }
      ) {
        year
        quarter1
        quarter2
        quarter3
        quarter4
      }
    }
  }
`;

export const useProductionHistoryChart = (yearsBack: number) => {
  const chartYearRange = useMemo(() => getPastYears(yearsBack), [yearsBack]);
  const { data, loading, error } = useQuery<GetViewerProductionHistoryQuery>(
    GET_PRODUCTION_HISTORY,
    {
      variables: {
        yearStart: chartYearRange[0],
        yearEnd: chartYearRange[chartYearRange.length - 1],
      },
    }
  );

  const productionHistoryChartData = useMemo((): ChartDataShape<
    Record<number, number>
  >[] => {
    if (!data?.viewer?.aggregateProductionHistoryByYear) return [];

    const result = FiscalQuarterValues.map((quarter) => {
      const recordKey = quarter
        .toLowerCase()
        .replace(' ', '') as keyof ProductionHistory;
      return data?.viewer.aggregateProductionHistoryByYear?.reduce(
        (acc, record) => {
          acc[record.year] = record[recordKey];
          return acc;
        },
        {
          quarter,
        } as Partial<ProductionHistoryDataShape>
      ) as ProductionHistoryDataShape;
    });

    return result.map((r) => ({
      key: r.quarter,
      data: chartYearRange.reduce((acc, y) => {
        acc[y] = r[y];
        return acc;
      }, {} as Record<number, number>),
    }));
  }, [chartYearRange, data]);

  const domain = useCumulativeDomain(
    productionHistoryChartData,
    data?.selectedReps
  );

  return {
    productionHistoryChartData,
    domain,
    loading,
    error,
    years: chartYearRange,
  };
};
