import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

import clsx from 'clsx';
import { TableInstance } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMeasure, LayeredIcon } from '@edgeco/react-components';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { getShadowGradient } from '.';

const headerTotalHeight = 60;
const headerPadding = 6;

const useStyles = makeStyles(({ extensions: { color } }: EdgeCoTheme) =>
  createStyles<any, any>({
    cell: {
      zIndex: 4,
      padding: headerPadding,
    },
    sticky: {
      zIndex: 5,
    },
    pagerCell: {
      height: 52,
      padding: 0,
      zIndex: 5,
    },
    headerCell: (props) => ({
      borderBottom: `1px solid ${color.borderDarker}`,
      top: props.stickyTop,
      fontSize: 21,
      fontWeight: 'bold',
      position: 'sticky',
      backgroundColor: color.background,
    }),
    headerCellContent: {
      display: 'flex',
      alignItems: 'center',
      minHeight: `calc(${headerTotalHeight}px - ${headerPadding * 2}px)`,
      whiteSpace: 'nowrap',
    },
    lastLeftShadow: {
      ...getShadowGradient('right'),
      marginTop: -1,
      height: 'calc(100% + 1px)',
    },
    bottomShadowRow: {
      position: 'sticky',
      padding: 0,
      border: 'none',
      zIndex: 5,
    },
    bottomShadow: {
      '&::after': {
        ...getShadowGradient('bottom'),
        display: 'block',
        content: '""',
      },
    },
    sortIconContainer: {
      position: 'relative',
    },
    sortIcon: {
      color: color.icon.dark2,
    },
    sorted: {
      color: color.primary,
    },
  })
);

type Props<D extends object> = {
  tableInstance: TableInstance<D>;
  hasHorizontalScroll?: boolean;
  stickyTop: number;
};

function TableHead<D extends object>(props: Props<D>) {
  const { tableInstance, hasHorizontalScroll, stickyTop } = props;
  const { headerGroups } = tableInstance;

  const classes = useStyles(props);

  const { ref, measurement } = useMeasure();
  return (
    <>
      {headerGroups.map((headerGroup) => {
        return (
          <React.Fragment key={headerGroup.getHeaderGroupProps().key}>
            {headerGroup.headers.map((header, headerIndex) => {
              const { style, ...headerProps } = header.getHeaderProps(
                header.getSortByToggleProps()
              );
              const renderedStyle = { ...style, zIndex: undefined };
              if (header.width !== undefined) {
                renderedStyle.width = header.width;
              }
              renderedStyle.minWidth = header.minWidth;
              return (
                <div
                  className={clsx(classes.cell, classes.headerCell, {
                    [classes.sticky]: header.sticky !== undefined,
                  })}
                  {...headerProps}
                  style={renderedStyle}
                  ref={headerIndex === 0 ? ref : undefined}
                >
                  <div className={classes.headerCellContent}>
                    <span>{header.render('Header')}</span>
                    {header.canSort && (
                      <div className={classes.sortIconContainer}>
                        <LayeredIcon>
                          <FontAwesomeIcon
                            className={clsx(
                              classes.sortIcon,
                              header.isSorted &&
                                !header.isSortedDesc &&
                                classes.sorted
                            )}
                            size="sm"
                            icon={faSortUp}
                          />
                          <FontAwesomeIcon
                            className={clsx(
                              classes.sortIcon,
                              header.isSortedDesc && classes.sorted
                            )}
                            size="sm"
                            icon={faSortDown}
                          />
                        </LayeredIcon>
                      </div>
                    )}
                  </div>
                  {hasHorizontalScroll &&
                    headerProps['data-sticky-last-left-td'] && (
                      <div className={classes.lastLeftShadow} />
                    )}
                </div>
              );
            })}
          </React.Fragment>
        );
      })}
      <div
        className={classes.bottomShadowRow}
        style={{
          gridColumnStart: '1',
          gridColumnEnd: '-1',
          top: measurement
            ? measurement.borderBoxSize.blockSize + stickyTop
            : 0,
        }}
      >
        <div className={classes.bottomShadow} />
      </div>
    </>
  );
}

export default TableHead;
