import { PropsWithChildren } from 'react';
import {
  createMuiTheme,
  createStyles,
  ThemeProvider,
  WithStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { RouteChildrenProps } from 'react-router';
import { defaultTheme, namedColors } from '@edgeco/react-components';
import PageFooter from './PageFooter';
import { ReactComponent as Logo } from '../../assets/imgs/wm_beta_logo.svg';

const {
  breakpoints,
  typography: { pxToRem },
  extensions: { color },
  spacing,
} = defaultTheme;

const innerTheme = createMuiTheme({
  typography: {
    ...defaultTheme.typography,
    body1: {
      fontSize: pxToRem(36),
      color: namedColors.tundora,
      [breakpoints.down('sm')]: {
        fontSize: pxToRem(30),
      },
      [breakpoints.down('xs')]: {
        fontSize: pxToRem(20),
      },
    },
    h2: {
      fontSize: pxToRem(60),
      color: color.primary,
      opacity: 0.78,
      fontFamily: '"Yu Mincho"',
      fontWeight: 800,
      [breakpoints.down('sm')]: {
        fontSize: pxToRem(40),
      },
      [breakpoints.down('xs')]: {
        fontSize: pxToRem(35),
      },
    },
  },
  overrides: {
    ...defaultTheme.overrides,
    MuiLink: {
      root: {
        color: color.primary,
        textDecoration: 'underline',
        cursor: 'pointer',
        userSelect: 'none',
      },
    },
  },
});

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    centerVertical: {
      display: 'flex',
      alignItems: 'center',
    },
    logoOuterContainer: {
      flexGrow: 1,
      display: 'flex',
    },
    logoContainer: {
      margin: 'auto',
      width: 1094,
    },
    messageRoot: {
      flexGrow: 2,
      maxWidth: 1350,
      padding: spacing(0, 3),
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  });

type Props = WithStyles<typeof styles> &
  PropsWithChildren<{}> &
  RouteChildrenProps<{}>;

function MessageRoot({ classes, children }: Props) {
  return (
    <div className={classes.root}>
      <div className={classes.logoOuterContainer}>
        <div className={classes.logoContainer}>
          <Logo />
        </div>
      </div>
      <ThemeProvider theme={innerTheme}>
        <div className={classes.messageRoot}>{children}</div>
      </ThemeProvider>
      <PageFooter />
    </div>
  );
}

export default withStyles(styles)(MessageRoot);
