import { useState } from 'react';
import { Button, Backdrop, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { TableInstance } from 'react-table';

import {
  useMessageBox,
  Loading,
  downloadString,
} from '@edgeco/react-components';
import { formatColumnValue } from '.';
import { ReactComponent as FileExport } from '../../../assets/imgs/file-export.svg';

const useStyles = makeStyles(({ spacing, zIndex }) => ({
  exportButton: {
    fontSize: '2.1rem',
    lineHeight: '2.1rem',
    marginRight: spacing(1),
  },
  exportIcon: {
    marginRight: spacing(0.5),
  },
  exportBackdrop: {
    zIndex: zIndex.modal,
  },
}));

export type ExportButtonProps<D extends object> = {
  instance: TableInstance<D>;
  fetchData: () => Promise<D[]>;
  additionalColumns?: (keyof D)[];
  fileName: string;
};

// TODO: increase size once this is done using Blob Storage
const MAX_EXPORT_SIZE = 5000;

export function ExportButton<D extends object>({
  instance,
  fetchData,
  additionalColumns,
  fileName,
}: ExportButtonProps<D>) {
  const {
    columns,
    columnDefinitions,
    pageCount,
    state: { pageSize },
  } = instance;
  const classes = useStyles();
  const rowCount = pageCount * pageSize;
  const [exporting, setExporting] = useState<boolean>(false);

  const { Modal, setOpen: openErrorMessage } = useMessageBox({
    isDraggable: false,
    title: 'File Size Too Large',
    width: 340,
    content:
      "File size exceeds the download limit and the file hasn't downloaded. Please narrow your search criteria and try again.",
    submitButton: {
      text: 'Ok',
    },
  });

  if (rowCount === 0) return null;

  const exportToCsv = async () => {
    if (rowCount > MAX_EXPORT_SIZE) {
      openErrorMessage(true);
      return;
    }
    setExporting(true);
    const selectedColumns: (keyof D)[] = [
      ...columns
        .filter((c) => c.isVisible && c.id !== 'expander')
        .map((c) => c.id),
      ...(additionalColumns ?? []),
    ] as (keyof D)[];
    const data = await fetchData();
    const fileContents = [
      `${selectedColumns.map((c) => columnDefinitions[c].header).join(',')}\n`,
      ...data.map((row) => {
        return `${selectedColumns
          .map((column) =>
            formatColumnValue(row[column as keyof D], columnDefinitions[column])
          )
          .map((formatted) => `"${formatted?.replaceAll('"', '""')}"`)
          .join(',')}\n`;
      }),
    ];
    setExporting(false);
    downloadString(
      fileContents,
      `${fileName}-${moment().format('YYYY-MM-DD_hh-mm-ss')}.csv`
    );
  };

  return (
    <>
      <Button className={classes.exportButton} onClick={exportToCsv}>
        <FileExport width={25} height={25} className={classes.exportIcon} />
        Export Data
      </Button>

      <Backdrop className={classes.exportBackdrop} open={exporting}>
        <Loading />
      </Backdrop>

      {Modal}
    </>
  );
}

export default ExportButton;
