import React, { useMemo } from 'react';
import {
  Container,
  Theme,
  createStyles,
  WithStyles,
  useTheme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { StickLeft, useMessageBox } from '@edgeco/react-components';

import { useViewerRepsQuery } from 'edgeco/graphql/advisor-summary/queries';
import { auth } from '../../libs/config/oidcConfig';
import PageHeader from './PageHeader';

type ClassKey = 'container' | 'content' | 'modal' | 'header';

type StyleProps = { paddingLeft?: number; paddingRight?: number };

const styles = ({ breakpoints, spacing, extensions }: Theme) =>
  createStyles<ClassKey, StyleProps>({
    container: ({ paddingLeft, paddingRight }) => ({
      height: '100%',
      paddingTop:
        extensions.toolbarHeight +
        spacing(extensions.pageWrapper.spacing.padding),

      [breakpoints.down('md')]: {
        paddingTop: extensions.toolbarHeight - 20,
      },
      paddingBottom: spacing(extensions.pageWrapper.spacing.padding),
      paddingLeft:
        paddingLeft ?? spacing(extensions.pageWrapper.spacing.padding),
      paddingRight:
        paddingRight ?? spacing(extensions.pageWrapper.spacing.padding),
      flexGrow: 1,
    }),
    content: {
      padding: spacing(0),
      height: `calc(100% - ${extensions.pageWrapper.headerHeight}px)`,
    },
    modal: {
      maxWidth: 440,
    },
    header: {},
  });

interface DecoratedProps extends WithStyles<typeof styles>, StyleProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  sticky?: boolean;
}

function PageWrapper({
  classes,
  title,
  children,
  sticky,
  ...rest
}: DecoratedProps) {
  const { spacing, extensions } = useTheme();
  const defaultPadding = spacing(extensions.pageWrapper.spacing.padding);
  const { paddingLeft = defaultPadding, paddingRight = defaultPadding } = rest;

  const { allViewerRepsQuery, loadingAllReps } = useViewerRepsQuery();
  const viewerHasReps: boolean = useMemo(
    () =>
      allViewerRepsQuery?.viewer?.reps !== null &&
      allViewerRepsQuery?.viewer?.reps !== undefined &&
      allViewerRepsQuery.viewer?.reps.length > 0,
    [allViewerRepsQuery]
  );

  const { Modal: RepErrorModal } = useMessageBox({
    className: classes.modal,
    isDraggable: false,
    title: 'Please Contact Us',
    open: !loadingAllReps && !viewerHasReps,
    content: `Additional information is needed. Please call us at (412) 745 - 5454
      to discuss, with one of our customer service representatives, how we
      can help you. Your experience with our product is of the utmost
      importance to us.`,
    hideHeader: true,
    submitButton: {
      text: 'Go Back to Login',
    },
    onSubmit: () => {
      auth.logout();
    },
    onCancel: () => {},
  });

  const header = <PageHeader className={classes.header} title={title} />;

  return (
    <Container className={classes.container} maxWidth="xl">
      {sticky ? (
        <StickLeft padding={paddingLeft + paddingRight}>{header}</StickLeft>
      ) : (
        header
      )}

      {RepErrorModal}
      <main className={classes.content}>
        {viewerHasReps ? children : <div></div>}
      </main>
    </Container>
  );
}

export default withStyles(styles)(PageWrapper);
