import { Widget } from '@edgeco/react-components';
import PayEarnedCharts from './PayEarnedCharts';
import PayEarnedTiles from './PayEarnedTiles';

function PayEarnedBreakdown() {
  return (
    <Widget title="Production Summary">
      <PayEarnedCharts />
      <PayEarnedTiles />
    </Widget>
  );
}

export default PayEarnedBreakdown;
