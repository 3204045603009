import {
  ApolloCache,
  ApolloClient,
  ApolloLink,
  createHttpLink,
  DefaultOptions,
  from,
  NormalizedCacheObject,
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

import { AccessTokenResponse, AuthenticationState } from 'react-aad-msal';

import { ai } from '../config/appInsightsConfig';
import { accessScopes, auth } from '../config/oidcConfig';
import appConfig from '../config/appConfig';

type Api = 'summary' | 'userProfile';

export const devPorts: Record<Api, number> = {
  summary: 5001,
  userProfile: 5003,
};

// TODO: Add stitched API endpoint once there are multiple graphql services (i.e.) https://graphql.macg.com which stitches summary.macg.com/graphql with other graphql schemas
const advisorSummaryUrl = () => {
  const api = 'summary';
  switch (appConfig.deployEnv) {
    case 'development':
    case 'development-api':
      return `https://localhost:${devPorts[api]}`;
    case 'uat':
    case 'qa':
      return `https://wealth.${appConfig.apiDomain}/api/summary/advisor`;
    default:
      return `https://${api}.${appConfig.apiDomain}`;
  }
};

const userProfileUrl = () => {
  const api = 'userProfile';
  switch (appConfig.deployEnv) {
    case 'development':
    case 'development-api':
      return `https://localhost:${devPorts[api]}`;
    case 'uat':
    case 'qa':
      return `https://${appConfig.apiDomain}/api/userprofile`;
    default:
      return `https://${api}.${appConfig.apiDomain}`;
  }
};

const httpLink = createHttpLink({
  uri: `${advisorSummaryUrl()}/graphql`,
});

const useProfileHttpLink = createHttpLink({
  uri: `${userProfileUrl()}/graphql`,
});

const combinedHttpLink = ApolloLink.split(
  (operation) => operation.getContext()?.clientName === 'user-profile',
  useProfileHttpLink,
  httpLink
);

let tokenResponse: AccessTokenResponse | undefined;

const authLink = setContext(async (_, { headers, ...context }) => {
  if (auth.authenticationState !== AuthenticationState.Authenticated) {
    return { headers, ...context };
  }
  // TODO: create base GQL queries that specify client
  const getScope = (client: 'user-profile' | 'advisor-summary'): string =>
    ({
      'user-profile': accessScopes.userProfileApi,
      'advisor-summary': accessScopes.summaryApi,
    }[client] ?? accessScopes.summaryApi);

  // an authentication token if we don't have one or it has expired
  tokenResponse = await auth.getAccessToken({
    scopes: [getScope(context.clientName)],
  });

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: tokenResponse.accessToken
        ? `Bearer ${tokenResponse.accessToken}`
        : null,
    },
    ...context,
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      // eslint-disable-next-line no-console
      console.error(
        `[GraphQL error]: Message: ${error.message}, Location: ${JSON.stringify(
          error.locations
        )}, Path: ${error.path}, Code: ${error.extensions?.code}`
      );
      ai.trackException({
        exception: new Error(error.message),
        properties: error,
      });
    });

  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`);
    ai.trackException({
      exception: networkError,
    });
  }
  if (
    graphQLErrors?.some((x) => x.extensions?.code === 'AUTH_NOT_AUTHORIZED')
  ) {
    window.location.href = '/error/401';
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
});

// Reference https://github.com/apollographql/apollo-client/blob/master/src/core/ApolloClient.ts#L32
const defaultOptions: DefaultOptions = {
  // watchQuery configures the useQuery hook
  watchQuery: {
    pollInterval: 30 * 60 * 1000, // m * s * ms => 30min
    fetchPolicy: 'cache-first',
    returnPartialData: true, // Possible bug here: this option will not require local fields (@client) to be populated before the rest of the query executes
    partialRefetch: true,
  },
  query: {
    fetchPolicy: 'cache-first',
  },
  mutate: {},
};

// ! This needs to be created outside of a component or function, or else it will cause an infinite loop
const createGraphQLClient = (
  persistedCache: ApolloCache<NormalizedCacheObject>
) =>
  new ApolloClient({
    cache: persistedCache,
    link: from([errorLink, retryLink, authLink, combinedHttpLink]),
    connectToDevTools: process.env.REACT_APP_DEPLOY_ENV !== 'production',
    defaultOptions,
    version: appConfig.appVersion,
    name: appConfig.appName,
  });

export default createGraphQLClient;
