export function getTnsUrl() {
  let tnsSubdomain: string;
  if (process.env.REACT_APP_DEPLOY_ENV.includes('development')) {
    tnsSubdomain = 'dev';
  } else if (
    process.env.REACT_APP_DEPLOY_ENV === 'qa' ||
    process.env.REACT_APP_DEPLOY_ENV === 'uat'
  ) {
    tnsSubdomain = 'qa';
  } else {
    tnsSubdomain = 'secure';
  }

  return `https://${tnsSubdomain}.macg.com`;
}
