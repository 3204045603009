import { SortingRule } from 'react-table';
import { FilterOperator } from '@edgeco/react-components';

function mapOperatorToGql(operator: string) {
  switch (operator) {
    case FilterOperator.GreaterThan:
      return 'gt';
    case FilterOperator.GreaterThanOrEqual:
      return 'gte';
    case FilterOperator.LessThan:
      return 'lt';
    case FilterOperator.LessThanOrEqual:
      return 'lte';
    case FilterOperator.Contains:
      return 'in';
    case FilterOperator.NotEquals:
      return 'neq';
    default:
      throw new Error(`invalid filter operator: ${operator}`);
  }
}

export function filterToWhere(filters: FilterRequestFilter[]) {
  return filters.reduce((acc: any, filter, index) => {
    const filterInput: any = {
      [filter.property]: {
        [mapOperatorToGql(filter.operator!)]: filter.value,
      },
    };
    if (index === 0) {
      return filterInput;
    }

    if (!acc.and) acc.and = [];

    acc.and.push(filterInput);
    return acc;
  }, {});
}

export function buildSort(sortRules: SortingRule<any>[]) {
  if (sortRules.length === 0) return undefined;
  return sortRules.reduce((acc: any, sort) => {
    acc[sort.id] = sort.desc ? 'DESC' : 'ASC';
    return acc;
  }, {});
}

export function numberToCursor(number: number = 0) {
  return btoa(number.toString());
}

export function pageToCursor(pageIndex?: number, pageSize?: number) {
  if (!pageIndex) return undefined; // if we're on the first page, we don't need a cursor
  const afterRow = pageIndex > 0 ? pageIndex * (pageSize ?? 40) - 1 : 0;
  return numberToCursor(afterRow);
}
