import React, { useMemo, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import moment from 'moment';
import {
  Line,
  YAxis,
  LineChart,
  XAxis,
  useYAxis,
  Loading,
  Widget,
  currencyFormatter,
  periodToRange,
  ALL_FILTERS,
  TooltipProps,
  useColorScheme,
} from '@edgeco/react-components';

import {
  ProductionDetailLink,
  ProductionDetailLinkType,
} from 'edgeco/components/routing';
import { ProductionCategoryValues } from 'edgeco/types';
import { useProductionHistoryChart } from 'edgeco/graphql/advisor-summary/queries';

const useStyles = makeStyles((theme: EdgeCoTheme) => ({
  widgetRoot: {
    display: 'flex',
    alignContent: 'center',
    margin: 'auto',
  },
  graphRoot: {
    display: 'flex',
    width: '100%',
    height: '500px',
    justifyContent: 'center',
  },
  tooltipContent: {
    fontSize: '1.8rem',
    fontWeight: 400,
    lineHeight: '2.1rem',
  },
  axisTickLabel: {
    ...theme.extensions.font.infographicTickLabelLg,
  },
}));

function ProductionHistoryWidget() {
  const colorScheme = useColorScheme();
  const { graphRoot, widgetRoot, tooltipContent } = useStyles();
  const {
    extensions: { color },
  } = useTheme<EdgeCoTheme>();

  const { productionHistoryChartData, loading, error, years, domain } =
    useProductionHistoryChart(4);

  const getColor = useCallback(
    (idx: number) => {
      return {
        stroke: colorScheme.forIndex(idx, years.length),
        activeFill: colorScheme.forIndex(idx, years.length, true),
      };
    },
    [years.length, colorScheme]
  );

  const renderChartObjects = useMemo(() => {
    const tooltipFormat =
      (year: number) =>
      ({ value, data: { key } }: TooltipProps) => {
        const quarter = parseInt(key.replace('Quarter ', ''));
        const refDate = moment()
          .utc()
          .set('year', year)
          .set('quarter', quarter);
        const [start, end] = periodToRange('Quarter', refDate);
        return (
          <div className={tooltipContent}>
            <div>
              <b>{currencyFormatter(value as number, 9)}</b>
            </div>
            <div>{year}</div>
            <ProductionDetailLink
              filter={{
                ...ProductionCategoryValues.reduce((acc, c) => {
                  acc[c] = ALL_FILTERS;
                  return acc;
                }, {} as any),
                startDate: start.toDate(),
                endDate: end.toDate(),
                datePeriod: 'Custom',
              }}
              linkType={ProductionDetailLinkType.DetailLink}
            />
          </div>
        );
      };

    return years.reduce((acc, year, idx) => {
      acc.push(
        <Line
          key={`1-line-${year}`}
          name={year}
          aria-label="chart-line"
          legendType="square"
          type="linear"
          dataKey={(d: any) => d.data[year]}
          getTooltipContent={tooltipFormat(year)}
          tooltipOptions={{ interactive: true }}
          {...getColor(idx)}
        />
      );
      return acc;
    }, [] as React.ReactElement[]);
  }, [years, tooltipContent, getColor]);

  const yAxisProps = useYAxis(domain);

  let inner;
  if (error) {
    inner = <div />;
  } else if (loading) {
    inner = <Loading />;
  } else {
    inner = (
      <LineChart
        xAxis={
          <XAxis
            tick={{
              fontSize: '2.1rem',
              fill: color.text.dark,
            }}
            padding={{ left: 100, right: 100 }}
            type="category"
          />
        }
        yAxis={
          <YAxis
            {...yAxisProps}
            tick={{
              fontSize: '2.1rem',
              fill: color.text.dark,
            }}
          />
        }
        data={productionHistoryChartData}
        nameKey="key"
        legendSeries={years}
      >
        {renderChartObjects}
      </LineChart>
    );
  }

  return (
    <Widget title="Production History" className={widgetRoot}>
      <div className={graphRoot}>{inner}</div>
    </Widget>
  );
}

export default ProductionHistoryWidget;
