import React, { useCallback, useEffect, useMemo } from 'react';
import {
  REQUIRED_GROUP,
  useTransferList,
  TransferList,
  TransferItem,
  groupObjectsBy,
  namedColors,
} from '@edgeco/react-components';
import { darken, makeStyles } from '@material-ui/core';

import {
  useViewerRepsQuery,
  useSelectedRepsQuery,
} from 'edgeco/graphql/advisor-summary/queries';

import { useSelectedRepsMutation } from 'edgeco/graphql/advisor-summary/mutations';

const useStyles = makeStyles(({ extensions: { color } }) => ({
  rootStyle: {
    width: '440px',
    flexDirection: 'column',
  },
  listText: {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: '2.1rem',
  },
  highlightedText: {
    borderBottom: `2px solid ${color.borderDarkest}`,
  },
  rootList: {
    color: color.text.dark,

    marginTop: '-4px',
    backgroundColor: color.background,

    '& > div:not(:first-child)': {
      paddingLeft: '50px', // 24px normally + 26px to offset from select all option
      '&:hover': {
        backgroundColor: darken(namedColors.concrete, 0.04),
      },
    },
    '& > div:nth-child(odd)': {
      backgroundColor: color.tableAltRow,
    },
  },
  selectAllItem: {
    backgroundColor: `${namedColors.mercury} !important`, //  important = Normally bad form, but this is a pretty niche part of the design
  },
  leftStyle: {
    backgroundColor: color.background,
    height: '100%',
  },
  pinnedListItem: {
    backgroundColor: `${color.background} !important`,
    '&:hover': {
      backgroundColor: `${namedColors.mercury} !important`,
    },
  },
}));

export const REP_PERSONAL_ID_GROUP = 'My RepIDs';
export const REP_MISC_ID_GROUP = 'Misc IDs';

function useRepSelection() {
  const classes = useStyles();

  const { selectedRepsQuery, loadingSelectedReps } = useSelectedRepsQuery();

  const { allViewerRepsQuery, loadingAllReps } = useViewerRepsQuery();

  const setSelectedReps = useSelectedRepsMutation();

  /**
   * Loads selected reps from the cache or selects the first available rep from all reps
   */
  const selectedReps = useMemo(
    () => selectedRepsQuery?.selectedReps ?? [],
    [selectedRepsQuery]
  );

  const repTransferItems = useMemo(() => {
    // Ensures that the Group order is My RepIds, Random Groups, then Misc Ids
    if (!allViewerRepsQuery?.viewer?.reps) return new Map();
    const reps = allViewerRepsQuery?.viewer.reps as Rep[];

    const repItems = reps.reduce(
      (acc, curr) => {
        const baseItem: TransferItem = {
          id: curr.repId.toString(),
          label: `Rep ID ${curr.repCode}, ${curr.firstName ?? ''} ${
            curr.lastName ?? ''
          }`,
          homeGroup: '',
          currentGroup: '',
          searchText: `${curr.repCode} ${curr.firstName} ${curr.lastName}`,
          isPinned: false,
        };

        // Rep is the currently logged in user
        if (curr.isViewer) {
          acc.primary.push({
            ...baseItem,
            homeGroup: REP_PERSONAL_ID_GROUP,
            currentGroup: REP_PERSONAL_ID_GROUP,
            isPinned: false,
          });
        }

        // Rep is part of a group
        if (curr.isGroup) {
          acc.groups.push({
            ...baseItem,
            homeGroup: `${curr.repGroup?.groupCode ?? ''} ${
              curr.repGroup?.groupName ?? ''
            }`,
            currentGroup: `${curr.repGroup?.groupCode ?? ''} ${
              curr.repGroup?.groupName ?? ''
            }`,
            isPinned: curr.isViewer,
          });
        }

        // Misc group, if rep is neither a personal rep or a part of some group
        if (!curr.isGroup && !curr.isViewer) {
          acc.misc.push({
            ...baseItem,
            homeGroup: REP_MISC_ID_GROUP,
            currentGroup: REP_MISC_ID_GROUP,
            isPinned: false,
          });
        }
        return acc;
      },
      {
        primary: [] as TransferItem[],
        groups: [] as TransferItem[],
        misc: [] as TransferItem[],
        selected: [] as TransferItem[],
      }
    );

    const mapped = groupObjectsBy(
      [...repItems.primary, ...repItems.groups, ...repItems.misc],
      (d: TransferItem) => d.homeGroup
    ) as Map<string | number, TransferItem[]>;
    mapped.delete(REQUIRED_GROUP);
    return mapped;
  }, [allViewerRepsQuery]);

  const transferListState = useTransferList({
    listDefinition: repTransferItems,
  });

  const { checkedItems, setCheckedItems } = transferListState;

  const clearSelection = useCallback(() => {
    setCheckedItems(new Set<string>());
  }, [setCheckedItems]);

  const resetSelection = useCallback(() => {
    const selectedIds = selectedReps.map((rep) => rep.toString());

    setCheckedItems(new Set<string>(selectedIds));
  }, [selectedReps, setCheckedItems]);

  useEffect(() => {
    if (loadingAllReps || loadingSelectedReps) return;

    resetSelection();
  }, [
    allViewerRepsQuery,
    loadingAllReps,
    loadingSelectedReps,
    resetSelection,
    selectedRepsQuery,
    setSelectedReps,
  ]);

  const selected: number[] = useMemo(
    () => [...checkedItems].map((x) => parseInt(x)),
    [checkedItems]
  );

  const applyRepSelection = useCallback(() => {
    setSelectedReps(selected);
  }, [selected, setSelectedReps]);

  return {
    TransferComponent: (
      <TransferList
        {...transferListState}
        showCheckedIndicator
        classes={classes}
        options={{
          variant: 'single',
          showSelectGroup: true,
        }}
      />
    ),
    selected,
    applyRepSelection,
    resetSelection,
    clearSelection,
  };
}

export default useRepSelection;
