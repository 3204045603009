import React from 'react';

import {
  EdgeCoProvider,
  useCreateFeatureFlagContext,
  LogConfig,
  LogLevel,
} from '@edgeco/react-components';

import { BrowserRouter as Router } from 'react-router-dom';
import { AppInsightsContextProvider } from 'edgeco/hooks/AppInsights';
import { ApolloProvider } from '@apollo/client';
import { useGraphQL } from 'edgeco/graphql';
import appConfig from 'edgeco/config/appConfig';
import { AppStateProvider, appReducer, initialState } from './state';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { rootRoute, RouteMap } from './libs/routes/routes';
import { Feature } from './@types/feature';

const App: React.FC = () => {
  const { apolloClient } = useGraphQL();

  const qaAndLower = ['development-api', 'development', 'qa'].includes(
    appConfig.deployEnv
  );
  const devOnly = ['development-api', 'development'].includes(
    appConfig.deployEnv
  );

  const flags: Record<Feature, boolean> = {
    [Feature.ProfilePage]: qaAndLower,
    [Feature.Future1Page]: devOnly, // it will be hidden in QA
    [Feature.Notifications]: devOnly, // it will be hidden at QA
  };
  const FeatureFlagContext = useCreateFeatureFlagContext(flags);

  let logConfig: LogConfig;
  switch (process.env.REACT_APP_DEPLOY_ENV) {
    case 'development-api':
    case 'development':
      logConfig = {
        default: LogLevel.Debug,
        components: LogLevel.Info,
      };
      break;
    default:
      logConfig = {
        default: LogLevel.Error,
      };
      break;
  }

  return (
    <>
      <EdgeCoProvider logConfig={logConfig}>
        <Router>
          <FeatureFlagContext.Provider value={flags}>
            <ApolloProvider client={apolloClient}>
              <AppStateProvider reducer={appReducer} init={initialState}>
                <AppInsightsContextProvider>
                  <RouteMap extendedRoute={rootRoute} />
                </AppInsightsContextProvider>
              </AppStateProvider>
            </ApolloProvider>
          </FeatureFlagContext.Provider>
        </Router>
      </EdgeCoProvider>
    </>
  );
};

export default App;
