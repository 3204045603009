/* eslint-disable no-param-reassign */
import { useCallback, useMemo } from 'react';
import { StringIndexable } from '@edgeco/react-components';
import {
  useGetLatest,
  actions,
  useMountedLayoutEffect,
  makePropGetter,
  ensurePluginOrder,
  TableState,
  TableInstance,
  ActionType,
  Row,
  IdType,
  Hooks,
  Meta,
} from 'react-table';

export function expandRows<D extends object>(
  rows: Row<D>[],
  {
    manualExpandedKey,
    expanded,
    expandSubRows = true,
    previouslyExpanded,
  }: {
    manualExpandedKey: string;
    expanded: Record<IdType<D>, boolean>;
    previouslyExpanded: Record<IdType<D>, boolean>;
    expandSubRows: boolean;
  }
) {
  const expandedRows: Row<D>[] = [];

  const handleRow = (row: Row<D>) => {
    row.isExpanded =
      (row.original && (row.original as StringIndexable)[manualExpandedKey]) ||
      expanded[row.id];

    row.wasPreviouslyExpanded =
      (row.original && (row.original as StringIndexable)[manualExpandedKey]) ||
      previouslyExpanded[row.id];

    row.canExpand = row.subRows && !!row.subRows.length;

    expandedRows.push(row);

    if (expandSubRows && row.subRows && row.subRows.length && row.isExpanded) {
      row.subRows.forEach(handleRow);
    }
  };

  rows.forEach(handleRow);

  return expandedRows;
}

// Actions
actions.resetExpanded = 'resetExpanded';
actions.toggleRowExpanded = 'toggleRowExpanded';
actions.toggleAllRowsExpanded = 'toggleAllRowsExpanded';

const defaultGetToggleAllRowsExpandedProps = <D extends object>(
  props: any,
  { instance }: { instance: TableInstance<D> }
) => [
  props,
  {
    onClick: () => {
      instance.toggleAllRowsExpanded();
    },
    style: {
      cursor: 'pointer',
    },
    title: 'Toggle All Rows Expanded',
  },
];

const defaultGetToggleRowExpandedProps = <D extends object>(
  props: any,
  { row }: { row: Row<D> }
) => [
  props,
  {
    onClick: () => {
      row.toggleRowExpanded();
    },
    style: {
      cursor: 'pointer',
    },
    title: 'Toggle Row Expanded',
  },
];

// Reducer
function reducer<D extends object>(
  state: TableState<D>,
  action: ActionType,
  _?: TableState<D>,
  instance?: TableInstance<D>
) {
  if (action.type === actions.init) {
    return {
      ...state,
      expanded: {},
      previouslyExpanded: {},
    };
  }

  if (action.type === actions.resetExpanded) {
    return {
      ...state,
      expanded: instance?.initialState?.expanded || {},
      previouslyExpanded: instance?.initialState?.expanded || {},
    };
  }

  if (action.type === actions.toggleAllRowsExpanded) {
    const { value } = action;
    const { isAllRowsExpanded, rowsById } = instance!;

    const expandAll = typeof value !== 'undefined' ? value : !isAllRowsExpanded;

    if (expandAll) {
      const expanded: any = {};
      const previouslyExpanded: any = {};

      Object.keys(rowsById).forEach((rowId) => {
        expanded[rowId] = true;
        previouslyExpanded[rowId] = true;
      });

      return {
        ...state,
        expanded,
        previouslyExpanded,
      };
    }

    return {
      ...state,
      expanded: {},
      previouslyExpanded: {},
    };
  }

  if (action.type === actions.toggleRowExpanded) {
    const { id, value: setExpanded } = action;
    const exists = state.expanded[id];

    const shouldExist =
      typeof setExpanded !== 'undefined' ? setExpanded : !exists;

    if (!exists && shouldExist) {
      return {
        ...state,
        expanded: {
          ...state.expanded,
          [id]: true,
        },
        previouslyExpanded: {
          ...state.previouslyExpanded,
          [id]: true,
        },
      };
    }
    if (exists && !shouldExist) {
      const { [id]: row, ...rest } = state.expanded;
      return {
        ...state,
        expanded: rest,
      };
    }
    return state;
  }

  return undefined;
}

function findExpandedDepth<D extends object>(
  expanded: Record<IdType<D>, boolean>
) {
  let maxDepth = 0;

  Object.keys(expanded).forEach((id) => {
    const splitId = id.split('.');
    maxDepth = Math.max(maxDepth, splitId.length);
  });

  return maxDepth;
}

function useInstance<D extends object>(instance: TableInstance<D>) {
  const {
    data,
    rows,
    rowsById,
    manualExpandedKey = 'expanded',
    paginateExpandedRows = true,
    expandSubRows = true,
    autoResetExpanded = true,
    getHooks,
    plugins,
    state: { expanded, previouslyExpanded, pageIndex = 0 },
    dispatch,
  } = instance;

  ensurePluginOrder(
    plugins,
    ['useSortBy', 'useGroupBy', 'usePivotColumns', 'useGlobalFilter'],
    'useExpanded'
  );

  const getAutoResetExpanded = useGetLatest(autoResetExpanded);

  let isAllRowsExpanded = Boolean(
    Object.keys(rowsById).length && Object.keys(expanded).length
  );

  if (isAllRowsExpanded) {
    if (Object.keys(rowsById).some((id) => !expanded[id])) {
      isAllRowsExpanded = false;
    }
  }

  // Bypass any effects from firing when this changes
  useMountedLayoutEffect(() => {
    if (getAutoResetExpanded()) {
      dispatch({ type: actions.resetExpanded });
    }
  }, [dispatch, data]);

  const toggleRowExpanded = useCallback(
    (id, value) => {
      dispatch({ type: actions.toggleRowExpanded, id, value });
    },
    [dispatch]
  );

  const toggleAllRowsExpanded = useCallback(
    (value) => dispatch({ type: actions.toggleAllRowsExpanded, value }),
    [dispatch]
  );

  const expandedRows = useMemo(() => {
    if (paginateExpandedRows) {
      return expandRows(
        rows.map((row) => ({ ...row, id: `${pageIndex}:${row.id}` })),
        {
          manualExpandedKey,
          expanded,
          previouslyExpanded,
          expandSubRows,
        }
      );
    }

    return rows;
  }, [
    paginateExpandedRows,
    rows,
    manualExpandedKey,
    expanded,
    previouslyExpanded,
    expandSubRows,
    pageIndex,
  ]);

  const expandedDepth = useMemo(() => findExpandedDepth(expanded), [expanded]);

  const getInstance = useGetLatest(instance);

  const getToggleAllRowsExpandedProps = makePropGetter(
    getHooks().getToggleAllRowsExpandedProps,
    { instance: getInstance() }
  );

  Object.assign(instance, {
    preExpandedRows: rows,
    expandedRows,
    rows: expandedRows,
    expandedDepth,
    isAllRowsExpanded,
    toggleRowExpanded,
    toggleAllRowsExpanded,
    getToggleAllRowsExpandedProps,
  });
}

function prepareRow<D extends object>(
  row: Row<D>,
  { instance: { getHooks }, instance }: Meta<D>
) {
  row.toggleRowExpanded = (set) =>
    instance.toggleRowExpanded(row.id as any, set);

  row.getToggleRowExpandedProps = makePropGetter(
    getHooks().getToggleRowExpandedProps,
    {
      instance,
      row,
    }
  );
}

export const useExpanded = <D extends object>(hooks: Hooks<D>) => {
  hooks.getToggleAllRowsExpandedProps = [defaultGetToggleAllRowsExpandedProps];
  hooks.getToggleRowExpandedProps = [defaultGetToggleRowExpandedProps];
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.prepareRow.push(prepareRow);
};

useExpanded.pluginName = 'useExpanded';
