import { Grid, makeStyles, Box, Theme } from '@material-ui/core';
import { CustomLegend as Legend, Loading } from '@edgeco/react-components';
import { ProductionCategoryValues } from 'edgeco/types';
import { usePayEarnedSummaryChart } from 'edgeco/graphql/advisor-summary/queries';
import PeriodContainer from './PeriodContainer';

const chartSize = 225;

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    margin: 'auto',
    paddingBottom: theme.spacing(3),
  },
}));

function PayEarnedCharts() {
  const classes = useStyles();
  const commonProps = {
    width: chartSize,
    height: chartSize,
  };

  const { payEarnedSummaryData, loading, error } = usePayEarnedSummaryChart();

  const wrapper = (component: React.ReactElement) => (
    <Box className={classes.gridContainer}>{component}</Box>
  );
  if (error) return wrapper(<div />);
  if (loading || !payEarnedSummaryData) return wrapper(<Loading />);

  return wrapper(
    <Grid container justify="center" spacing={2}>
      <PeriodContainer
        period="Yesterday"
        totalLabel="Previous Day’s Total Earnings"
        chartShape={payEarnedSummaryData.Day}
        {...commonProps}
      />
      <PeriodContainer
        period="MTD"
        totalLabel="Month to Date Total Earnings"
        chartShape={payEarnedSummaryData.Month}
        {...commonProps}
      />
      <PeriodContainer
        period="YTD"
        totalLabel="Year to Date Total Earnings"
        chartShape={payEarnedSummaryData.Year}
        {...commonProps}
      />
      <PeriodContainer
        period="T12"
        totalLabel="12 Months Total Earnings"
        chartShape={payEarnedSummaryData.T12}
        {...commonProps}
      />
      <Grid item>
        <Legend series={[...ProductionCategoryValues]} />
      </Grid>
    </Grid>
  );
}

export default PayEarnedCharts;
