import { useMemo } from 'react';
import { Loading, MissingData } from '@edgeco/react-components';
import { makeStyles, Theme } from '@material-ui/core';

import clsx from 'clsx';

import { SubComponentProps, TableInstance, Row } from 'react-table';
import { useAppStateValue } from 'edgeco/hooks/useAppStateValue';
import TableRow from './TableRow';
import TableHead from './TableHead';

const useStyles = makeStyles<Theme, { templateColumns: string }>(
  ({ extensions: { color } }) => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    table: (props) => ({
      display: 'grid',
      gridTemplateColumns: props.templateColumns,
      flex: '1',
    }),
    body: {
      '& > tr:nth-child(4n-1) > td': {
        background: color.tableAltRow,
      },
    },
    loading: {
      height: 250,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      gridColumnStart: 1,
      gridColumnEnd: -1,
    },
    missingDataRow: {
      gridColumnStart: 1,
      gridColumnEnd: -1,
      padding: 16,
    },
    missingDataContainer: {
      margin: 'auto',
      width: 'max-content',
    },
  })
);

type TableProps<D extends object = {}> = {
  className?: string;
  subComponent?: (props: SubComponentProps<D>) => React.ReactElement;
  instance: TableInstance<D>;
  stickyTop?: number;
};

export function Table<D extends object = {}>({
  className,
  subComponent,
  instance,
  stickyTop = 0,
}: TableProps<D>) {
  const templateColumns = useMemo(() => {
    const columnWidths = instance.visibleColumns.map((c) => {
      if (c.width === undefined) {
        return 'auto';
      }

      return `${c.width}px`;
    });
    return columnWidths.join(' ');
  }, [instance.visibleColumns]);
  const classes = useStyles({ templateColumns });

  const { getTableProps, prepareRow, visibleColumns, page } = instance;
  const [
    {
      scroll: { horizontal: hasHorizontalScroll },
    },
  ] = useAppStateValue();
  let body;
  if (instance.state.loading) {
    body = (
      <div className={classes.loading}>
        <Loading />
      </div>
    );
  } else if (
    instance.pageCount <= 0 ||
    (instance.pageCount === 1 && instance.page.length === 0)
  ) {
    body = (
      <div className={classes.missingDataRow}>
        <div className={classes.missingDataContainer}>
          <MissingData
            size="sm"
            variant="warn"
            description="Your filter criteria yielded no results."
          />
        </div>
      </div>
    );
  } else {
    body = (
      <>
        {page.map((row: Row<D>) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          return (
            <TableRow
              key={rowProps.key}
              row={row}
              rowProps={rowProps}
              columnCount={visibleColumns.length}
              subComponent={subComponent}
              hasHorizontalScroll={hasHorizontalScroll}
            />
          );
        })}
      </>
    );
  }
  return (
    <div className={clsx(className, classes.root)}>
      <div className={classes.table} {...getTableProps()}>
        <TableHead
          tableInstance={instance}
          hasHorizontalScroll={hasHorizontalScroll}
          stickyTop={stickyTop}
        />
        {body}
      </div>
    </div>
  );
}

export default Table;
