import { gql, useQuery } from '@apollo/client';
import { ChartDataShape } from '@edgeco/react-components';
import { useMemo } from 'react';
import { GetTopPayoutAccountsQuery } from '../@types';

export const GET_TOP_PAYOUT_ACCOUNTS = gql`
  query GetTopPayoutAccounts($repIds: [Int!]!, $timeFrame: TimeFrame) {
    selectedReps @client @export(as: "repIds")
    viewer {
      aggregateTopPayout(repIds: $repIds, timeFrame: $timeFrame) {
        name: account
        value: payout
        total
      }
    }
  }
`;

export const useTopPayoutAccountsChart = (
  timeFrame: TopPayoutAccountDatePeriod
) => {
  const { data, loading, error } = useQuery<GetTopPayoutAccountsQuery>(
    GET_TOP_PAYOUT_ACCOUNTS,
    {
      variables: {
        timeFrame: timeFrame.toUpperCase(),
      },
    }
  );
  const queryResult = useMemo(
    () => data?.viewer?.aggregateTopPayout ?? [],
    [data?.viewer?.aggregateTopPayout]
  );

  const [totalTop, totalPercent] = useMemo(() => {
    const top = queryResult.reduce((acc, r) => acc + r.value, 0);
    const production = queryResult[0]?.total;
    return [top, top / production];
  }, [queryResult]);

  const topPayoutAccountsChartData: ChartDataShape<TopDataShape>[] = useMemo(
    () =>
      queryResult
        ? queryResult.map((r) => ({
            key: r.name,
            data: {
              ...r,
              percentOfTotal: r.value / totalTop,
            },
          }))
        : [],
    [queryResult, totalTop]
  );

  return { topPayoutAccountsChartData, loading, error, totalPercent };
};
